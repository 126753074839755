import * as requestFromServer from "./availabilitysCrud";
import {availabilitysSlice, callTypes} from "./availabilitysSlice";

const {actions} = availabilitysSlice;

export const fetchAvailabilitys = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllAvailabilitys(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data.data;
      
      dispatch(actions.availabilitysFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find availabilitys";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAvailability = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findAvailabilitys(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data.data;
      dispatch(actions.availabilitysFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find availabilitys";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOneAvailability = id => dispatch => {
  if (!id) {
    return dispatch(actions.availabilityFetched({ availabilityForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAvailabilityById(id)
    .then(response => {
      const availability = response.data.data;
      dispatch(actions.availabilityFetched({ availabilityForEdit: availability }));
    })
    .catch(error => {
      error.clientMessage = "Can't find availability";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteAvailability = id => async(dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let response
  try{
    response = await requestFromServer.deleteAvailability(id)
    if (response.data.status){
      dispatch(actions.availabilityDeleted({ id }));
    } 
    return response
  } catch(error){
    error.clientMessage = response.response && response.response.data.message || "Can't delete availability";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const createAvailability = availabilityForCreation => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let response
  try{
    response = await requestFromServer.createAvailability(availabilityForCreation)
    if (response.data && response.data.status){
      const { data }  = response.data;
      dispatch(actions.availabilityCreated({ data }));
    } 
    return response
  } catch(error){
    error.clientMessage = response.response && response.response.data.message || "Can't create availability";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const updateAvailability = availability => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let response
  try{
    response = await requestFromServer.updateAvailability(availability)
    if (response.data.status){
      dispatch(actions.availabilityUpdated({ availability }))
    } 
    return response
  } catch(error){
    error.clientMessage = response.response && response.response.data.message || "Can't update availability";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const approveAvailability = availability => async(dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let response
  try{
    response = await requestFromServer.approveAvailability(availability)
    if (response.data.status){
      const data  = response.data
      dispatch(actions.availabilityApproved({ availability: data }))
    } 
    return response
  } catch(error){
    error.clientMessage = response.response && response.response.data.message || "Can't approve availability";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const createAvailabilityDailySettings = availabilityForCreation => async(dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let response
  try{
    response = await requestFromServer.createAvailabilityDailySetting(availabilityForCreation)
    if (response.data.status){
      const { data } = response.data;
      dispatch(actions.availabilityDailySettingsCreated({ data }));
    } 
    return response
  } catch(error){
    error.clientMessage = response.response && response.response.data.message || "Can't create daily settings"
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};


export const updateAvailabilityDailySettings = availabilityForCreation => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let response
  try{
    response = await requestFromServer.updateAvailabilityDailySetting(availabilityForCreation)
    console.log("response", response)
    if (response.data.status){
      const dailySetting = JSON.parse(response.config.data)
      const availability  = dailySetting.dailySetting;
      dispatch(actions.availabilityDailySettingsUpdated({ availability }))
    } 
    return response
  } catch(error){
    error.clientMessage = response.response && response.response.data.message || "Can't update daily settings"
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const fetchStatusFilters = queryParams => dispatch => {

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .filterAvailabilityByStatus(queryParams)
    .then(response => {
      console.log("Filter response", response)
      const result = response.data.data;
      dispatch(actions.filtersFetched({ filters: result }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const updateAvailabilitysStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForAvailabilitys(ids, status)
    .then(() => {
      dispatch(actions.availabilitysStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update availabilitys status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteAvailabilitys = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAvailabilitys(ids)
    .then(() => {
      dispatch(actions.availabilitysDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete availabilitys";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
