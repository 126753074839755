/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ScheduleIcon from "@material-ui/icons/Schedule";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import TimerIcon from "@material-ui/icons/Timer";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector, shallowEqual } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const { role_level } = useSelector(
    state => ({
      role_level: state.auth.user.role_details.role_level
    }),
    shallowEqual
  );

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <DashboardIcon />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Applications</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* eCommerce */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/e-commerce",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/e-commerce">
            <span className="svg-icon menu-icon">
              <EventAvailableIcon />
            </span>
            <span className="menu-text">Availability</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Availability</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/timesheet/availability"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/timesheet/availability">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">My Availability</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::Availability calendar*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/timesheet/availability-calendar"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/timesheet/availability-calendar"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Availability Calendar</span>
                </NavLink>
              </li>
              {/*end::Availability calendar*/}
              {/*begin::2 Level*/}
              <>
                {role_level <= 4 && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/timesheet/team-availability"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/timesheet/team-availability"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Team Availability</span>
                    </NavLink>
                  </li>
                )}
              </>
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* eCommerce */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/e-commerce",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/e-commerce">
            <span className="svg-icon menu-icon">
              <ScheduleIcon />
            </span>
            <span className="menu-text">Schedule</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Schedule</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/timesheet/schedule"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/timesheet/schedule">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">My Schedule</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::Schedule calendar*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/timesheet/schedule-calendar"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/timesheet/schedule-calendar"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Schedule Calendar</span>
                </NavLink>
              </li>
              {/*end::Schedule calendar*/}
              {/*begin::2 Level*/}
              {/*begin::2 Level*/}
              {role_level <= 4 && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/timesheet/team-schedule"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/timesheet/team-schedule">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Team Schedule</span>
                  </NavLink>
                </li>
              )}

              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">Timesheet</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}

        {/* Project */}
        {/*begin::1 Level*/}
        {/* { role_level <= 4 &&
          <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/timesheet/project",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
        <NavLink className="menu-link" to="/timesheet/project">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
          </span>
          <span className="menu-text">Project</span>     
        </NavLink>
        </li>
         } */}

        {/*end::1 Level*/}

        {/* Timesheet */}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
