import axios from "axios";

export const SCHEDULES_URL =
  process.env.REACT_APP_API_ENDPOINT + "/main/schedules";

// CREATE =>  POST: add a new schedule to the server
export function createSchedule(schedule) {
  return axios.post(SCHEDULES_URL, { schedule });
}

// READ
export function getAllSchedules(queryParams) {
  return axios.get(`${SCHEDULES_URL}`);
}

export function getScheduleById(scheduleId) {
  return axios.get(`${SCHEDULES_URL}/${scheduleId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSchedules() {
  return axios.get(`${SCHEDULES_URL}?user=all`);
}

// UPDATE => PUT: update the schedule on the server
export function updateSchedule(schedule) {
  return axios.put(`${SCHEDULES_URL}/${schedule.id}`, { schedule });
}

// UPDATE Status
export function updateStatusForSchedules(ids, status) {
  return axios.post(`${SCHEDULES_URL}/updateStatusForSchedules`, {
    ids,
    status
  });
}

// DELETE => delete the schedule from the server
export function deleteSchedule(scheduleId) {
  return axios.delete(`${SCHEDULES_URL}/${scheduleId}`);
}

// DELETE Schedules by ids
export function deleteSchedules(ids) {
  return axios.post(`${SCHEDULES_URL}/deleteSchedules`, { ids });
}
