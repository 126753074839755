import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ScheduleIcon from "@material-ui/icons/Schedule";
import moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import * as schedulesActions from "../../modules/ECommerce/_redux/schedules/schedulesActions";
import * as availabilitysActions from "../../modules/ECommerce/_redux/availabilitys/availabilitysActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ProgressHelpers from "../../../_metronic/_helpers/ProgressHelpers";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const getDaysOfWeekBetweenDates = (sDate, eDate) => {
  const dayNames = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday"
  ];
  const startDate = moment(sDate);
  const endDate = moment(eDate);

  endDate.add(1, "day");

  const daysOfWeek = [];

  let i = 0;

  while (i < 7 && startDate < endDate) {
    const _startDate = new Date(startDate);
    daysOfWeek.push([
      dayNames[_startDate.getDay()],
      moment(_startDate).format("YYYY-MM-DD")
    ]);
    startDate.add(1, "day");
    i++;
  }

  return daysOfWeek;
};

function increase24TimeByOne(timeStr) {
  let [hours, minutes] = timeStr.split(":");
  return `${((+hours - 1) % 24).toString().padStart(2, "0")}:${minutes}`;
}

export function Dashboard() {
  const [toggle, setToggle] = useState(false);
  const [modal, setModal] = useState(false);
  const [event, setEvent] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [mode, setMode] = useState("today");
  const [availabilityCount, setAvailabilityCount] = useState(0);
  const [unavailabilityCount, setUnavailabilityCount] = useState(0);
  const [scheduleCount, setScheduleCount] = useState(0);
  const [scheduleDuration, setScheduleDuration] = useState("0h 0m");

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleModalToggle = () => {
    setModal(!modal);
  };

  // https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd#:~:text=The%20simplest%20way%20to%20convert,getTimezoneOffset()%20*%2060000%20))%20.
  const handleEventClick = arg => {
    const eventStartDate = new Date(arg.event.start).toLocaleDateString();

    const startZone = arg.event.start.toLocaleTimeString().slice(-2);
    const endZone = arg.event.end.toLocaleTimeString().slice(-2);

    const eventStartTime = increase24TimeByOne(
      arg.event.start.toLocaleTimeString()
    );
    const eventEndTime = increase24TimeByOne(
      arg.event.end.toLocaleTimeString()
    );

    const _eventClickData = {
      // name: arg.event.extendedProps.name,
      title: arg.event.title,
      name: arg.event.extendedProps.name,
      status: arg.event.extendedProps.status,
      date: new Date(arg.event.start).toDateString(),
      startTime: eventStartTime,
      endTime: eventEndTime
    };
    handleModalToggle();
    setEvent(_eventClickData);
  };

  // Getting curret state of teamAvailabilitys list from store (Redux)
  const { currentState, schedulesState } = useSelector(
    state => ({
      currentState: state.availabilitys,
      schedulesState: state.schedules
    }),
    shallowEqual
  );

  const { entities } = currentState;

  const getEventData = () => {
    let eventData = [];
    let availabilityStatusCount = 0;
    let unavailabilityStatusCount = 0;
    entities.forEach(_entity => {
      if (_entity && _entity.status === "approved") {
        let start, end, status, day;
        const startDate = _entity.startDate;
        const endDate = _entity.endDate;

        const availabilityWeeks = getDaysOfWeekBetweenDates(startDate, endDate);

        _entity.dailySettings.forEach(_set => {
          status = _set.status;
          day = _set.day;

          if (_set.status === "available") {
            availabilityStatusCount += 1;
          } else {
            unavailabilityStatusCount += 1;
          }

          availabilityWeeks.forEach(_availabilityWeek => {
            let _dayOfWeek = _availabilityWeek[0];
            let _date = _availabilityWeek[1];

            if (_dayOfWeek === day) {
              start = _date + "T" + _set.startTime;
              end = _date + "T" + _set.endTime;
            }
          });

          eventData.push({ title: _entity.title, start, end, status });
        });

        setEventData(eventData);
        setAvailabilityCount(availabilityStatusCount);
        setUnavailabilityCount(unavailabilityStatusCount);
      }
    });
  };

  const calcDayHourMinSec = data => {
    let totalScheduleHours = 0;
    let totalScheduleMins = 0;
    let _totalScheduleDuration;
    if (data.length === 0) {
      setScheduleDuration("0h 0m");
    } else {
      data.forEach(_data => {
        let end = _data.date + "T" + _data.endTime;
        let start = _data.date + "T" + _data.startTime;

        let diffTime = new Date(end).valueOf() - new Date(start).valueOf();
        let days = diffTime / (24 * 60 * 60 * 1000);
        let hours = (days % 1) * 24;
        let minutes = (hours % 1) * 60;
        let secs = (minutes % 1) * 60;

        [days, hours, minutes, secs] = [
          Math.floor(days),
          Math.floor(hours),
          Math.floor(minutes),
          Math.floor(secs)
        ];

        if (totalScheduleMins < 60) {
          totalScheduleHours += hours;
          totalScheduleMins += minutes;

          if (totalScheduleMins > 60) {
            totalScheduleMins -= 60;
            totalScheduleHours += 1;
          }
        } else {
          totalScheduleHours += 1;
          // totalScheduleMins += minutes
        }

        console.log(totalScheduleHours, totalScheduleMins);
        _totalScheduleDuration =
          totalScheduleHours + "h " + totalScheduleMins + "m";
      });
      setScheduleDuration(_totalScheduleDuration);
    }
  };

  const changeMode = s => {
    let scheduleData = schedulesState.entities;
    let d = new Date();
    d.setDate(d.getDate() - 1);
    let yesterday = d.toISOString().split("T")[0];

    let currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    let day = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();

    let tomorrow = year + "-" + month + "-" + day;

    if (s === "yesterday") {
      const newData = scheduleData.filter(_data => yesterday === _data.date);
      calcDayHourMinSec(newData);
      setMode("yesterday");
      setScheduleCount(newData.length);
    } else if (s === "month") {
      const newData = scheduleData.filter(
        _data => new Date().getMonth() === new Date(_data.date).getMonth()
      );
      calcDayHourMinSec(newData);
      setMode("month");
      setScheduleCount(newData.length);
    } else if (s === "today") {
      const newData = scheduleData.filter(
        _data => _data.date === new Date().toLocaleDateString("en-CA")
      );
      calcDayHourMinSec(newData);
      setMode("today");
      setScheduleCount(newData.length);
    } else if (s === "tomorrow") {
      const newData = scheduleData.filter(_data => _data.date === tomorrow);
      calcDayHourMinSec(newData);
      setMode("tomorrow");
      setScheduleCount(newData.length);
    }
  };

  // teamAvailabilitys Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    let status = "";
    dispatch(schedulesActions.fetchSchedules());
    dispatch(availabilitysActions.fetchAvailabilitys(status));
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // getScheduleData()
    getEventData();
  }, [dispatch, toggle]);

  return (
    <>
      <div className="py-3">
        <div className="col-6">
          <span className="switch switch-success d-flex">
            <strong style={{ marginRight: "8px", fontSize: "18px" }}>
              Dashboard:{" "}
            </strong>
            <label>Schedule</label>
            <label style={{ margin: "0 4px" }}>
              <input type="checkbox" name="select" onClick={handleToggle} />
              <span></span>
            </label>
            <label>Availability</label>
          </span>
        </div>
      </div>
      {/*<!--End::Row-->*/}

      <div>
        {toggle ? (
          <>
            <div className="row">
              <div className="col-xl-6">
                {/*<!--begin::Stats Widget 25-->*/}
                <div className="card card-custom bg-gray-100">
                  {/*<!--begin::Body-->*/}
                  <div className="card-body">
                    <span className="svg-icon svg-icon-2x svg-icon-success">
                      <EventNoteIcon />
                    </span>
                    <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                      {availabilityCount}
                    </span>
                    <span className="font-weight-bold text-muted font-size-sm">
                      Availability Count
                    </span>
                  </div>
                  {/*<!--end::Body-->*/}
                </div>
                {/*<!--end::Stats Widget 25-->*/}
              </div>
              <div className="col-xl-6">
                {/*<!--begin::Stats Widget 26-->*/}
                <div className="card card-custom bg-light-danger card-stretch gutter-b">
                  {/*<!--begin::ody-->*/}
                  <div className="card-body">
                    <span className="svg-icon svg-icon-2x svg-icon-danger">
                      <ScheduleIcon />
                    </span>
                    <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                      {unavailabilityCount}
                    </span>
                    <span className="font-weight-bold text-muted font-size-sm">
                      Unavailability Count
                    </span>
                  </div>
                  {/*<!--end::Body-->*/}
                </div>
                {/*<!--end::Stats Widget 26-->*/}
              </div>
            </div>

            <div title="Availability">
              {/*<!--begin::Advance Table Widget 8-->*/}
              {/* https://www.codegrepper.com/code-examples/javascript/event+full+calendar+modal+react */}
              <FullCalendar
                timeZone="UTC"
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                eventDisplay="block"
                headerToolbar={{
                  left: "today prev,next",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay"
                }}
                events={eventData}
                dayMaxEventRows={true}
                moreLinkClick="day"
                eventClick={handleEventClick}
              />
              <Modal
                isOpen={modal}
                toggle={handleModalToggle}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <ModalHeader toggle={handleModalToggle}>
                  Status - <strong> {event.status} </strong>
                </ModalHeader>
                <ModalBody>
                  {event.status === "available" ? "Available" : "Unavailable"}{" "}
                  <span style={{ fontWeight: "600" }}>{event.date}</span>{" "}
                  between{" "}
                  <span style={{ fontWeight: "600" }}>{event.startTime}</span>{" "}
                  and <span style={{ fontWeight: "600" }}>{event.endTime}</span>
                </ModalBody>
                <ModalFooter>
                  <ProgressHelpers
                    handleSubmit={handleModalToggle}
                    text={"Cancel"}
                  />
                </ModalFooter>
              </Modal>
              {/*<!--end::Advance Table Widget 8-->*/}
            </div>
          </>
        ) : (
          <div title="Schedule">
            <div className="py-3">
              <button
                onClick={() => changeMode("today")}
                className={
                  mode === "today"
                    ? "btn btn-primary btn-sm py-3 px-8 mx-2"
                    : "btn btn-white btn-sm py-3 px-8 mx-2"
                }
              >
                Today
              </button>
              <button
                onClick={() => changeMode("yesterday")}
                className={
                  mode === "yesterday"
                    ? "btn btn-primary btn-sm py-3 px-8 mx-2"
                    : "btn btn-white btn-sm py-3 px-8 mx-2"
                }
              >
                Yesterday
              </button>
              <button
                onClick={() => changeMode("tomorrow")}
                className={
                  mode === "tomorrow"
                    ? "btn btn-primary btn-sm py-3 px-8 mx-2"
                    : "btn btn-white btn-sm py-3 px-8 mx-2"
                }
              >
                Tomorrow
              </button>
              <button
                onClick={() => changeMode("month")}
                className={
                  mode === "month"
                    ? "btn btn-primary btn-sm py-3 px-8 mx-2"
                    : "btn btn-white btn-sm py-3 px-8 mx-2"
                }
              >
                Month
              </button>
            </div>
            {/*<!--begin::Advance Table Widget 8-->*/}
            <div className="row">
              <div className="col-xl-12">
                {/*<!--begin::Stats Widget 25-->*/}
                <div className="card card-custom bg-gray-100 card-stretch gutter-b">
                  {/*<!--begin::Body-->*/}
                  <div className="card-body">
                    <span className="svg-icon svg-icon-3x svg-icon-gray d-block my-2">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Media/Equalizer.svg"
                        )}
                      ></SVG>
                    </span>
                    <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"></span>
                    <span className="font-weight-bold text-muted font-size-sm"></span>
                  </div>
                  {/*<!--end::Body-->*/}
                </div>
                {/*<!--end::Stats Widget 25-->*/}
              </div>
              <div className="col-xl-6">
                {/*<!--begin::Stats Widget 25-->*/}
                <div className="card card-custom bg-light-warning card-stretch gutter-b">
                  {/*<!--begin::Body-->*/}
                  <div className="card-body">
                    <span className="svg-icon svg-icon-2x svg-icon-success">
                      <EventNoteIcon />
                    </span>
                    <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                      {scheduleCount}
                    </span>
                    <span className="font-weight-bold text-muted font-size-sm">
                      Schedule Count
                    </span>
                  </div>
                  {/*<!--end::Body-->*/}
                </div>
                {/*<!--end::Stats Widget 25-->*/}
              </div>
              <div className="col-xl-6">
                {/*<!--begin::Stats Widget 26-->*/}
                <div className="card card-custom bg-light-success card-stretch gutter-b">
                  {/*<!--begin::ody-->*/}
                  <div className="card-body">
                    <span className="svg-icon svg-icon-2x svg-icon-danger">
                      <ScheduleIcon />
                    </span>
                    <span className="card-title font-weight-bolder text-success-75 font-size-h2 mb-0 mt-6 d-block">
                      {scheduleDuration}
                    </span>
                    <span className="font-weight-bold text-muted font-size-sm">
                      Total Schedule Duration
                    </span>
                  </div>
                  {/*<!--end::Body-->*/}
                </div>
                {/*<!--end::Stats Widget 26-->*/}
              </div>
            </div>

            {/*<!--end::Advance Table Widget 8-->*/}
          </div>
        )}
      </div>
    </>
  );
}
