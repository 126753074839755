import axios from "axios";

export const APIURL = process.env.REACT_APP_API_ENDPOINT;

export const LOGIN_URL = "/api/auth/login";
export const CHECK_USER_EXISTANCE_URL = "/api/user/check_user_existance";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = APIURL + "api/me";

export function login(email, password) {
  return axios.post(APIURL + LOGIN_URL, { email, password });
}

export function check_user_existance(token) {
  return axios.post(APIURL + CHECK_USER_EXISTANCE_URL, { token });
}

export function create_user(data) {
  return axios.post(APIURL + "/api/user/create_user", data);
}

export function create_user_with_userdata(data) {
  return axios.post(APIURL + "/api/user/create_user_with_userdata", data);
}

export async function login_with_user_module(data) {
  return axios.post(APIURL + "/api/user/login_with_user_module", data);
}

export async function login_with_user_module_with_user_data(data) {
  return axios.post(APIURL, data);
}

export async function login_using_keys(data) {
  return axios.post(APIURL + "/api/user/login_using_keys", data);
}

export function register(email, fullname, username, password) {
  return axios.post(APIURL + REGISTER_URL, {
    email,
    fullname,
    username,
    password
  });
}

export function requestPassword(email) {
  return axios.post(APIURL + REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
