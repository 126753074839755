import {createSlice} from "@reduxjs/toolkit";

const initialProjectsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  projectForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState: initialProjectsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProjectById
    projectFetched: (state, action) => {
      state.actionsLoading = false;
      state.projectForEdit = action.payload.projectForEdit;
      state.error = null;
    },
    // findProjects
    projectsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createProject
    projectCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.data);
    },
    // updateProject
    projectUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.project.id) {
          return action.payload.project;
        }
        return entity;
      });
    },
    // deleteProject
    projectDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // getFilters
    filtersFetched: (state, action) => {
      state.actionsLoading = false;
      const object = action.payload.filters
      for (const property in object) {
        state.filters[property] = object[property]
      }
      state.error = null;
    },
    // deleteProjects
    projectsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // projectsUpdateState
    projectsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
