import axios from "axios";

export const AVAILABILITYS_URL = process.env.REACT_APP_API_ENDPOINT + "/main/availabilities";
export const AVAILABILITYS_DAILYSETTINGS_URL = process.env.REACT_APP_API_ENDPOINT + "/main/dailysettings";

// CREATE =>  POST: add a new availability to the server
export function createAvailability(availability) {
  return axios.post(AVAILABILITYS_URL, { availability });
}

// CREATE =>  POST: add a new dailysettings to the a particular availability
export function createAvailabilityDailySetting(dailySetting) {
  return axios.post(AVAILABILITYS_DAILYSETTINGS_URL, { dailySetting });
}

// UPDATE =>  POST: update a dailysettings to the a particular availability
export function updateAvailabilityDailySetting(dailySetting) {
  return axios.put(`${AVAILABILITYS_DAILYSETTINGS_URL}/${dailySetting.id}`, { dailySetting });
}

// READ
export function getAllAvailabilitys(queryParams) {
  return axios.get(`${AVAILABILITYS_URL}?status=${queryParams}`);
}

export function getAvailabilityById(availabilityId) {
  return axios.get(`${AVAILABILITYS_URL}/${availabilityId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findAvailabilitys(queryParams) {
  return axios.get(`${AVAILABILITYS_URL}?sortOrder=desc&sortField=startDate`);
}

// UPDATE => PUT: update the availability on the server
export function updateAvailability(availability) {
  return axios.put(`${AVAILABILITYS_URL}/${availability.id}`, { availability });
}

// APPROVE => PUT: approve the availability on the server
export function approveAvailability(id, userId) {
  const availability = { userId }
  return axios.put(`${AVAILABILITYS_URL}/${id}/approve`, { availability });
}

// UPDATE Status
export function updateStatusForAvailabilitys(ids, status) {
  return axios.post(`${AVAILABILITYS_URL}/updateStatusForAvailabilitys`, {
    ids,
    status
  });
}

// DELETE => delete the availability from the server
export function deleteAvailability(availabilityId) {
  return axios.delete(`${AVAILABILITYS_URL}/${availabilityId}`);
}

// FILTER => filter availabilities by status
export function filterAvailabilityByStatus(availabilityStatus) {
  return axios.get(`${AVAILABILITYS_URL}?status=${availabilityStatus}`);
}

// DELETE Availabilitys by ids
export function deleteAvailabilitys(ids) {
  return axios.post(`${AVAILABILITYS_URL}/deleteAvailabilitys`, { ids });
}
