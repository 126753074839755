import {createSlice} from "@reduxjs/toolkit";

const initialteamAvailabilitysState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  availabilityForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const teamAvailabilitysSlice = createSlice({
  name: "teamAvailabilitys",
  initialState: initialteamAvailabilitysState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getteamAvailabilityById
    teamAvailabilityFetched: (state, action) => {
      state.actionsLoading = false;
      state.availabilityForEdit = action.payload.availabilityForEdit;
      state.error = null;
    },
    // findteamAvailabilitys
    teamAvailabilitysFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createteamAvailability
    teamAvailabilityCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.data);
    },
    // updateteamAvailabilityDailySettings
    teamAvailabilityDailySettingsCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.data);
    },
    // updateteamAvailability
    teamAvailabilityUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.availability.id) {
          return action.payload.availability;
        }
        return entity;
      });
    },
    // updateteamAvailabilityDailySettings
    teamAvailabilityDailySettingsUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities = state.entities.map(entity => {
         entity.dailySettings.map(_ent => {
           if (_ent.id === Number(action.payload.availability.id)) {
              return action.payload.availability;
           }
            return _ent
      })
        return entity;
      });
    },
    // deleteteamAvailability
    teamAvailabilityDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteteamAvailabilitys
    teamAvailabilitysDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // teamAvailabilitysUpdateState
    teamAvailabilitysStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
