import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import * as authCrud from "../_redux/authCrud";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import { FormattedMessage } from "react-intl";
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  public_key: "",
  private_key: "",
  module_id: ""
};

function Login(props) {
  const dispatch = useDispatch();
  let useparams = useParams();

  const [public_key, setPublicKey] = useState(
    useparams.public_key && decodeURIComponent(useparams.public_key)
  );
  const [private_key, setPrivateKey] = useState(
    useparams.private_key && decodeURIComponent(useparams.private_key)
  );
  const [module_id, setModuleId] = useState(
    useparams.module_id && decodeURIComponent(useparams.module_id)
  );

  // const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    public_key: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(500, "Maximum 500 symbols")
      .required("Required Field"),
    private_key: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(500, "Maximum 500 symbols")
      .required("Required Field"),
    module_id: Yup.string().required("Required Field")
  });
  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async values => {
      setPrivateKey(values.private_key);
      setPublicKey(values.public_key);
      setModuleId(values.module_id);
    }
  });
  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const login_with_user_module = async (tokentemp, module_id) => {
    try {
      setLoading("login_with_user_module");

      let response = {};
      response = await authCrud.login_with_user_module({
        token: tokentemp,
        module_id: module_id
      });

      response = response.data;

      setLoading("");
      if (response.status) {
        //we have token in this response
        let authToken = response.token;
        let user = response.user;

        //save this token

        dispatch(auth.actions.login(authToken));
        dispatch(auth.actions.fulfillUser(user));
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          html: response.message,
          confirmButtonColor: "#1BC5BD"
        });
      }
    } catch (error) {
      setLoading();
      Swal.fire({
        icon: "error",
        title: "Error",
        html:
          typeof error === "string"
            ? error
            : error.response
            ? error.response.data.message
            : "Unable to login!!! Try again",
        confirmButtonColor: "#1BC5BD"
      });
    }
  };

  const login_using_keys = async () => {
    try {
      //we need to send this data to backend

      let map = {
        public_key,
        private_key,
        module_id
      };

      setLoading("login_using_keys");
      let response = await authCrud.login_using_keys(map);
      response = response.data;
      setLoading();
      if (response.status) {
        let authToken = response.token;
        let user = response.user;

        dispatch(auth.actions.login(authToken));
        dispatch(auth.actions.fulfillUser(user));
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          html: response.message,
          confirmButtonColor: "#1BC5BD"
        });
      }
    } catch (error) {
      setLoading();
      Swal.fire({
        icon: "error",
        title: "Error",
        html:
          typeof error === "string"
            ? error
            : error.response
            ? error.response.data.message
            : "Unable to login!!! Try again",
        confirmButtonColor: "#1BC5BD"
      });
    }
  };

  useEffect(() => {
    if (public_key && private_key && module_id) {
      login_using_keys();
    }
    // eslint-disable-next-line
  }, [public_key, private_key, module_id]);

  useEffect(() => {
    window.addEventListener(
      "message",
      async event => {
        let url = process.env.REACT_APP_USER_MODULE_URL;
        url = url.split("/");

        var protocol = url[0];
        var host = url[2];
        var origin = protocol + "//" + host;
        if (event.origin === origin) {
          //window.location.href = "<%= redirectURL %>/moduleauthenticated?token=" + event.data.token + '&module_id=' + event.data.module_id

          //we need to send this token to API, and check that is this a new user or a previous user
          //if this is a new user then we need to ask them for their role i.e. Teacher or student
          setLoading("login_with_user_module");
          login_with_user_module(event.data.token, event.data.module_id);
        }
        return;
      },
      false
    );
    return;
    // eslint-disable-next-line
  }, []);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">Enter your login details</p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        <div className="text-center mb-5">
          <button
            className="btn btn-primary btn-block"
            type="button"
            onClick={() =>
              window.open(
                process.env.REACT_APP_USER_MODULE_URL,
                "test",
                "width=500,height=500"
              )
            }
            disabled={loading}
          >
            {loading === "login_with_user_module" ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Sign in with UserModule"
            )}
          </button>
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Public Key"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "public_key"
            )}`}
            name="public_key"
            {...formik.getFieldProps("public_key")}
          />
          {formik.touched.public_key && formik.errors.public_key ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.public_key}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Private Key"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "private_key"
            )}`}
            name="private_key"
            {...formik.getFieldProps("private_key")}
          />
          {formik.touched.private_key && formik.errors.private_key ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.private_key}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Module Id"
            type="module_id"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "module_id"
            )}`}
            name="module_id"
            {...formik.getFieldProps("module_id")}
          />
          {formik.touched.module_id && formik.errors.module_id ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.module_id}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <div className="text-dark-50 text-hover-primary my-3 mr-2"></div>
          {/*<Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>*/}
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={loading}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default connect(null, auth.actions)(Login);
