import * as requestFromServer from "./schedulesCrud";
import {schedulesSlice, callTypes} from "./schedulesSlice";

const {actions} = schedulesSlice;

export const fetchSchedules = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllSchedules()
    .then(response => {
      const { totalCount, entities } = response.data.data;
      
      dispatch(actions.schedulesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find schedules";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSchedule = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSchedules(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data.data;
      dispatch(actions.schedulesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find schedules";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOneSchedule = id => dispatch => {
  if (!id) {
    return dispatch(actions.scheduleFetched({ scheduleForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getScheduleById(id)
    .then(response => {
      const schedule = response.data;
      dispatch(actions.scheduleFetched({ scheduleForEdit: schedule }));
    })
    .catch(error => {
      error.clientMessage = "Can't find schedule";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchedule = id => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let response
  try{
    response = await requestFromServer.deleteSchedule(id)
    if (response.data.status){
      dispatch(actions.scheduleDeleted({ id }));
    } 
    return response
  } catch(error){
    error.clientMessage = response.response && response.response.data.message || "Can't delete schedule";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const createSchedule = scheduleForCreation => async(dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let response
  try{
    response = await requestFromServer.createSchedule(scheduleForCreation)
    if (response.data.status){
      const { data } = response.data;
      dispatch(actions.scheduleCreated({ data }));
    } 
    return response
  } catch(error){
    error.clientMessage = response.response && response.response.data.message || "Can't create schedule";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};


export const updateSchedule = schedule => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let response
  try{
    response = await requestFromServer.updateSchedule(schedule)
    if (response.data.status){
      dispatch(actions.scheduleUpdated({ schedule }));
    } 
    return response
  } catch(error){
    error.clientMessage = response.response && response.response.data.message || "Can't update schedule";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const updateSchedulesStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForSchedules(ids, status)
    .then(() => {
      dispatch(actions.schedulesStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update schedules status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSchedules = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSchedules(ids)
    .then(() => {
      dispatch(actions.schedulesDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete schedules";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
