import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { availabilitysSlice } from "../app/modules/ECommerce/_redux/availabilitys/availabilitysSlice";
import { settingsSlice } from "../app/modules/ECommerce/_redux/settings/settingsSlice";
import { schedulesSlice } from "../app/modules/ECommerce/_redux/schedules/schedulesSlice";
import { projectsSlice } from "../app/modules/ECommerce/_redux/projects/projectsSlice";
import { timesheetsSlice } from "../app/modules/ECommerce/_redux/timesheets/timesheetsSlice";
import { teamSchedulesSlice } from "../app/modules/ECommerce/_redux/teamSchedules/teamSchedulesSlice";
import { teamAvailabilitysSlice } from "../app/modules/ECommerce/_redux/teamAvailabilitys/teamAvailabilitysSlice";
import { generalSlice } from "../app/modules/ECommerce/pages/General/_redux/generalSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  availabilitys: availabilitysSlice.reducer,
  teamAvailabilitys: teamAvailabilitysSlice.reducer,
  settings: settingsSlice.reducer,
  schedules: schedulesSlice.reducer,
  projects: projectsSlice.reducer,
  timesheets: timesheetsSlice.reducer,
  teamSchedules: teamSchedulesSlice.reducer,
  general: generalSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
